/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  useColorModeValue,
  BoxProps,
  Stack,
  Text,
  Image,
  Avatar,
  Button,
  Link,
  Select,
  Divider,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { NavItem } from '../navItem';
import { PrivateRoutes } from '../../routes/private.routes';
import logo from '../../assets/images/logo.png';
import logo2 from '../../assets/images/logo2.png';
import arrow from '../../assets/images/arrow.png';
import arrow2 from '../../assets/images/arrow2.png';
import { useAuth } from '../../providers/auth';
import { useUnit } from '../../hooks/useUnit';
import { getAuthStorage } from '../../services/storage';
import { STORAGE_KEYS } from '../../constants/Config';
import { useFetch } from '../../hooks/useFetch';
import DarkSelect from '../DarkSelect';

interface SidebarProps extends BoxProps {
  onToggle: () => void;
  isOpen: boolean;
}

const FixSidebar = ({ isOpen, onClick }: any) => {
  return (
    <Flex
      height="50px"
      background="#005D7453"
      justify={isOpen ? 'end' : 'center'}
      align="center"
      cursor="pointer"
      onClick={onClick}
    >
      <Image
        src={isOpen ? arrow : arrow2}
        w="10px"
        transition="0s ease"
        mr={isOpen ? '20px' : '0'}
      />
    </Flex>
  );
};

const Sidebar = ({ onToggle, isOpen, ...rest }: SidebarProps) => {
  const { Logout, user } = useAuth();
  const navigate = useNavigate();
  const [unitId, setUnitId] = useState<number | string>('');
  const [clientId, setClientId] = useState<number | string>('');
  const { unitData, updateClient, updateUnit, unit, client } = useUnit();
  const baseUrlClient = '/v1/admin/clients';
  const { requestApi: reqApiClient, data: dataClient } = useFetch(baseUrlClient);
  const baseUrlUnit = '/v1/admin/units';
  const { requestApi: reqApiUnit, data: dataUnit } = useFetch(baseUrlUnit);

  const onClickMenuChangeSize = () => {
    onToggle();
  };

  const getUnitAndClient = async () => {
    const unitStorage = await getAuthStorage(STORAGE_KEYS.UNIT);
    const clientStorage = await getAuthStorage(STORAGE_KEYS.CLIENT);
    setUnitId(unitStorage);
    setClientId(clientStorage);
  };

  useEffect(() => {
    getUnitAndClient();
  }, [unit, client]);

  useEffect(() => {
    reqApiClient('get', {
      params: { page: 1, per_page: 100 },
    });
  }, []);

  useEffect(() => {
    if (clientId) {
      reqApiUnit(
        'get',
        {
          params: { page: 1, per_page: 100 },
        },
        `${baseUrlUnit}?client_id=${clientId}`,
      );
    }
  }, [clientId]);

  const routeSettings = [
    {
      groupId: 1,
      name: 'system',
      routes: ['/clients', '/units', '/users', '/recover', '/profile', '/sectors'],
      redirect: '/units',
    },
    {
      groupId: 2,
      name: 'admin',
      routes: ['/clients', '/units', '/users', '/profile', '/sectors'],
      redirect: '/units',
    },
    {
      groupId: 3,
      name: 'client',
      routes: [
        '/',
        '/numeros',
        '/analytics',
        '/decisions',
        '/emotions',
        '/profile',
        '/insert-numbers',
      ],
      redirect: '/',
    },
  ];
  const findUser = routeSettings.find(item => item.groupId === user.group.id);

  return (
    <Stack
      position="fixed"
      transition="0.5s ease"
      bg="linear-gradient(195deg, rgba(15,22,25,1) 35%, rgba(59,78,104,1) 75%, rgba(97,120,173,1) 100%)"
      w={isOpen ? '300px' : '64px'}
      overflowY="auto"
      overflowX="hidden"
      h="full"
      justify="space-between"
      {...rest}
    >
      <Box>
        <FixSidebar isOpen={isOpen} onClick={onClickMenuChangeSize} />

        <Flex justify="center">
          <Image
            src={isOpen ? logo2 : logo}
            w={isOpen ? '180px' : '30px'}
            transition="0s ease"
            onClick={() => {
              if (user?.group?.id >= 3) {
                navigate('/numeros');
              }
            }}
            cursor={user?.group?.id >= 3 ? 'pointer' : 'default'}
            mt="50px"
          />
        </Flex>

        {isOpen && (
          <>
            <Flex transition="0.5s ease" m="5% 0 0 45px" direction="column" align="start">
              {findUser?.routes.find(routeByUser => routeByUser === '/') && (
                <Flex m="40px 20px 0 0px" align="center">
                  <Box ml="5px" mr="15px">
                    <Box>
                      <Text
                        fontSize="14px"
                        fontWeight="bold"
                        color={useColorModeValue('text.light.primary', 'text.light.primary')}
                      >
                        Marca
                      </Text>
                      <DarkSelect
                        value={clientId}
                        alphabetical
                        onChange={async e => {
                          setClientId(e.target.value);
                          await updateClient(Number(e.target.value)).then(async () => {
                            await reqApiUnit(
                              'get',
                              null,
                              `${baseUrlUnit}?client_id=${e.target.value}`,
                            );
                          });
                        }}
                        data={dataClient}
                      />
                      {/* <Select
                        variant="unstyled"
                        color="white"
                        _checked={{ color: 'black' }}
                        placeholder="Selecione uma marca"
                        value={clientId}
                        onChange={async e => {
                          setClientId(e.target.value);
                          await updateClient(Number(e.target.value)).then(async () => {
                            await reqApiUnit(
                              'get',
                              null,
                              `${baseUrlUnit}?client_id=${e.target.value}`,
                            );
                          });
                        }}
                      >
                        {dataClient?.data?.map(itemClient => (
                          <option
                            key={itemClient.id}
                            style={{ color: 'black' }}
                            value={itemClient.id}
                          >
                            {itemClient.name}
                          </option>
                        ))}
                      </Select> */}
                    </Box>

                    <Box mt="20px">
                      <Text
                        fontSize="14px"
                        fontWeight="bold"
                        color={useColorModeValue('text.light.primary', 'text.light.primary')}
                      >
                        {unitData?.type}
                      </Text>
                      <DarkSelect
                        value={unitId}
                        alphabetical
                        onChange={async e => {
                          await setUnitId(e.target.value);
                          await updateUnit(Number(e.target.value)).then(() => {
                            navigate(0);
                          });
                        }}
                        data={dataUnit}
                      />
                      {/* <Select
                        variant="unstyled"
                        color="white"
                        _focus={{ color: 'black' }}
                        _placeholder={{ color: 'black' }}
                        value={unitId}
                        placeholder="Selecione uma unidade"
                        onChange={async e => {
                          await setUnitId(e.target.value);
                          await updateUnit(Number(e.target.value)).then(() => {
                            navigate(0);
                          });
                        }}
                      >
                        {dataUnit?.data?.map(itemUnit => (
                          <option key={itemUnit.id} style={{ color: 'black' }} value={itemUnit.id}>
                            {itemUnit.name}
                          </option>
                        ))}
                      </Select> */}
                    </Box>
                  </Box>
                </Flex>
              )}
              <Link href="/profile" mt="20px">
                <Flex flexDirection="row" alignItems="center" mb="40px">
                  <Box>
                    <Text
                      ml="5px"
                      fontSize="14px"
                      fontWeight="bold"
                      color={useColorModeValue('theme.light.body', 'theme.dark.body')}
                    >
                      Usuário
                    </Text>
                    <Text ml="5px" color={useColorModeValue('theme.light.body', 'theme.dark.body')}>
                      {user.name}
                    </Text>
                  </Box>
                </Flex>
              </Link>
            </Flex>
            <Divider orientation="horizontal" />
          </>
        )}

        <Box mt={isOpen ? '5%' : '40%'}>
          <Box>
            {((unitId && user.group.id === 3) || user.group.id < 3) &&
              PrivateRoutes.filter(item => item.localeSidebar === 'top' && item.showSidebar)
                .filter(item =>
                  findUser?.routes.find(
                    routeByUser => item.path === routeByUser || routeByUser.includes(item.path),
                  ),
                )
                .filter(item => item.path !== '/profile')
                .map(link => (
                  <>
                    {((link.name === 'Números' && unitData?.show_numbers) ||
                      (link.name === 'Análises' && unitData?.show_analysis) ||
                      (link.name === 'Decisões' && unitData?.show_decisions) ||
                      (link.name === 'Emoções' && unitData?.show_emotions) ||
                      user.group.id < 3) && (
                      <NavItem key={link.name} href={link.path} isOpen={isOpen}>
                        {link.name}
                      </NavItem>
                    )}
                  </>
                ))}
          </Box>
          <Box mt={isOpen ? '5%' : '40%'}>
            <Divider orientation="horizontal" mb={isOpen ? '5%' : '40%'} />
            {unitId &&
              user.group.id === 3 &&
              PrivateRoutes.filter(item => item.localeSidebar === 'bottom' && item.showSidebar)
                .filter(item =>
                  findUser?.routes.find(
                    routeByUser => item.path === routeByUser || routeByUser.includes(item.path),
                  ),
                )
                .map(link => (
                  <>
                    {((link.name === 'Números' && unitData?.show_numbers) ||
                      (link.name === 'Análises' && unitData?.show_analysis) ||
                      (link.name === 'Decisões' && unitData?.show_decisions) ||
                      (link.name === 'Emoções' && unitData?.show_emotions) ||
                      user.group.id === 3) && (
                      <NavItem
                        key={link.name}
                        href={link.path}
                        isOpen={isOpen}
                        alternativesPaths={link.alternativePaths}
                      >
                        {link.name}
                      </NavItem>
                    )}
                  </>
                ))}
            {!!dataClient?.data.find(_client => _client.id === 4) && (
              <NavItem key="minhas demandas" href="/my-demands" isOpen={isOpen}>
                Minhas Demandas
              </NavItem>
            )}
          </Box>
        </Box>
      </Box>

      <Box>
        <Flex justify={isOpen ? 'start' : 'center'} m={isOpen ? '0 0 50px 45px' : '0 0 50px 0'}>
          <Button variant="link" onClick={Logout}>
            <Text
              color={useColorModeValue('theme.light.body', 'theme.dark.body')}
              fontWeight="bold"
            >
              Sair
            </Text>
          </Button>
        </Flex>

        <FixSidebar isOpen={isOpen} onClick={onClickMenuChangeSize} />
      </Box>
    </Stack>
  );
};

export default Sidebar;

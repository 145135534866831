import { AiOutlineSelect } from 'react-icons/ai';
import { BsBarChart } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { RiCommunityLine, RiEmotionHappyLine, RiUserSettingsLine } from 'react-icons/ri';
import { CgProfile } from 'react-icons/cg';
import { TbZoomQuestion, TbPresentationAnalytics } from 'react-icons/tb';
import ResetPassPage from '../pages/auth/resetPassword';
import ClientsPage from '../pages/clients';
import DashboardPage from '../pages/dashboard';
import DecisionsPage from '../pages/decisions';
import EmotionsPage from '../pages/emotions';
import SelectUnitPage from '../pages/selectUnit';
import UnitsPage from '../pages/units';
import UsersPage from '../pages/users';
import ProfilePage from '../pages/profile';
import SectorsPage from '../pages/sectors';
import IndicatorsPage from '../pages/insert-numbers/indicators';
import MyDemandsPage from '../pages/MyDemands';

export const PrivateRoutes = [
  {
    name: 'Sel. unidade',
    path: '/',
    component: SelectUnitPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: false,
    localeSidebar: 'top',
    icon: AiOutlineSelect,
  },
  {
    name: 'Resetar senha',
    path: '/recover',
    component: ResetPassPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: false,
    localeSidebar: 'top',
    icon: AiOutlineSelect,
  },
  /*  {
    name: 'Inserir meus números',
    path: '/insert-numbers',
    component: SelectIndicatorPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'bottom',
    icon: BsBarChart,
    alternativePaths: ['indicators'],
  }, */
  {
    name: 'Indicadores',
    path: '/indicators',
    component: false,
    componentCreate: false,
    componentEdit: false,
    componentShow: IndicatorsPage,
    showSidebar: true,
    localeSidebar: 'bottom',
    icon: BsBarChart,
    alternativePaths: ['indicators'],
  },
  {
    name: 'Números',
    path: '/numeros',
    component: DashboardPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: BsBarChart,
    noMargin: true,
  },
  {
    name: 'Análises',
    path: '/analytics',
    component: DashboardPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: TbPresentationAnalytics,
    noMargin: true,
  },
  {
    name: 'Clientes',
    path: '/clients',
    component: ClientsPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: FiUsers,
  },
  {
    name: 'Unidades',
    path: '/units',
    component: UnitsPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: RiCommunityLine,
  },
  {
    name: 'Setores',
    path: '/sectors',
    component: SectorsPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: RiCommunityLine,
  },
  {
    name: 'Decisões',
    path: '/decisions',
    component: DecisionsPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: TbZoomQuestion,
  },
  {
    name: 'Emoções',
    path: '/emotions',
    component: EmotionsPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: RiEmotionHappyLine,
  },
  {
    name: 'Usuários',
    path: '/users',
    component: UsersPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: RiUserSettingsLine,
  },
  {
    name: 'Perfil',
    path: '/profile',
    component: ProfilePage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: CgProfile,
  },
  {
    name: 'Minhas demandas',
    path: '/my-demands',
    component: MyDemandsPage,
    componentCreate: false,
    componentEdit: false,
    componentShow: false,
    showSidebar: true,
    localeSidebar: 'top',
    icon: TbZoomQuestion,
  },
];

import React, { useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { CreateButton } from '../buttons/CreateButton';
import { useFetch } from '../../hooks/useFetch';
// import { useTranslation } from 'react-i18next';

export default function ModalCreate({
  isOpen,
  onClose,
  RenderedForm,
  itemIdUrl = '',
  smallerModal,
  ...rest
}: any) {
  // const { t } = useTranslation();
  const { requestApi, data } = useFetch(itemIdUrl);

  useEffect(() => {
    if (!!itemIdUrl && isOpen) {
      requestApi('get', null, itemIdUrl);
    }
  }, [isOpen]);

  return (
    <>
      <CreateButton {...rest} />
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          height="550px"
          maxW={smallerModal ? '600px' : '950px'}
          boxShadow="0px 3px 6px #00000029"
          borderRadius="20px"
        >
          <ModalHeader
            height="70px"
            bg="#EFEFEF 0% 0% no-repeat padding-box"
            borderRadius="20px 20px 0px 0px"
          >
            {itemIdUrl && isOpen ? 'Editar' : 'Criar'} {rest.description}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" mt="30px">
            <RenderedForm
              onClose={onClose}
              defaultValues={itemIdUrl ? data?.data : {}}
              itemIdUrl={itemIdUrl}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputHookForm } from '../../inputs/InputHookForm';
import { FormButton } from '../../buttons/FormButton';
import { SelectInputForm } from '../../inputs/SelectInputForm';
import { useFetch } from '../../../hooks/useFetch';
import { useUnit } from '../../../hooks/useUnit';

interface DecisionFormProps {
  defaultValues?: any;
  onClose: any;
  itemIdUrl: any;
}

interface FormInputsProps {
  name: string;
  value: string;
  placeholder?: string;
  label: string | undefined;
  yup: any;
}

export default function DecisionForm({
  defaultValues = {},
  onClose,
  itemIdUrl,
}: DecisionFormProps) {
  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');
  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const [update, setUpdate] = useState(false);
  const { unit, client } = useUnit();
  const baseUrl = `/v1/admin/units/${unit}/decisions`;
  const baseUrlSecondary = `/v1/admin/users?client_id=${client}`;
  const { requestApi, data, loading } = useFetch(baseUrl);
  const {
    requestApi: requestApiResponsibles,
    data: dataResponsibles,
    /*  loading: loadingResponsibles, */
  } = useFetch(baseUrlSecondary);

  const formInputs: FormInputsProps[] = [
    {
      name: 'description',
      value: defaultValues.description ?? '',
      placeholder: 'Descrição',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'indicator',
      value: defaultValues.indicator ?? '',
      placeholder: 'Indicador',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'user_id',
      value: defaultValues.user?.id ?? '',
      placeholder: 'Responsável',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'execution_date',
      value: defaultValues.execution_date ?? null,
      placeholder: 'Data de execução',
      label: undefined,
      yup: Yup.string().nullable(),
    },
    {
      name: 'status',
      value: defaultValues.status ?? 'Em Aberto',
      placeholder: 'Status',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
  ];

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const description = formInputs.filter(field => field.name === 'description').shift();
  const indicator = formInputs.filter(field => field.name === 'indicator').shift();
  const userId = formInputs.filter(field => field.name === 'user_id').shift();
  const executionDate = formInputs.filter(field => field.name === 'execution_date').shift();
  const status = formInputs.filter(field => field.name === 'status').shift();

  const onSubmit = async (values: any) => {
    await requestApi(defaultValues.description ? 'put' : 'post', values, itemIdUrl);
    setUpdate(old => !old);
  };

  useEffect(() => {
    if (defaultValues.description) {
      reset(defaultValuesSchema);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (data?.msg === 'Registered successfully' || data?.msg === 'Updated successfully') {
      onClose();
    }
  }, [data?.msg]);

  useEffect(() => {
    if (unit) {
      requestApi('get');
    }
  }, [update, unit]);
  useEffect(() => {
    if (client) {
      requestApiResponsibles('get');
    }
  }, [update, client]);

  return (
    <Box h="100%" width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" justify="space-evenly">
          <InputHookForm
            name={description?.name}
            label={description?.label}
            placeholder={description?.placeholder}
            register={register}
            errors={errors}
          />
          <InputHookForm
            name={indicator?.name}
            label={indicator?.label}
            placeholder={indicator?.placeholder}
            register={register}
            errors={errors}
          />
          <SelectInputForm
            name={userId?.name}
            label={userId?.label}
            options={[
              ...(dataResponsibles?.data?.map(item => ({
                label: item?.name,
                value: item?.id,
              })) ?? []),
            ]}
            placeholder={userId?.placeholder}
            control={control}
            errors={errors}
          />

          <InputHookForm
            name={executionDate?.name}
            label={executionDate?.label}
            placeholder={executionDate?.placeholder}
            register={register}
            errors={errors}
            type="date"
          />

          <SelectInputForm
            name={status?.name}
            label={status?.label}
            options={[
              {
                label: 'Em Aberto',
                value: 'Em Aberto',
              },
              {
                label: 'Fechado',
                value: 'Fechado',
              },
            ]}
            placeholder={status?.placeholder}
            control={control}
            errors={errors}
          />

          <Flex justify="space-between">
            <FormButton
              mt={4}
              variant="outline"
              isLoading={isSubmitting || loading}
              onClick={onClose}
              w={isSmallerThan1280 ? 280 : 250}
            >
              Fechar
            </FormButton>
            <FormButton
              mt={4}
              isLoading={isSubmitting || loading}
              type="submit"
              w={isSmallerThan1280 ? 280 : 250}
            >
              Salvar decisão
            </FormButton>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
}

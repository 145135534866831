/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  Heading,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Draggable from 'react-draggable';
import { VscEmptyWindow } from 'react-icons/vsc';
import { DeleteButton } from '../../components/buttons/DeleteButton';
import { SaveButton } from '../../components/buttons/SaveButton';
import DecisionForm from '../../components/forms/DecisionForm';
import { SearchFilter } from '../../components/inputs/filters/search';
import Loader from '../../components/Loader';
import ModalForm from '../../components/ModalForm';
import { Paginator } from '../../components/paginator';
import { PaginationType, useFetch } from '../../hooks/useFetch';
import { useUnit } from '../../hooks/useUnit';
import EmptySvg from '../../components/EmptySvg';
import ConfirmModal from '../../components/ConfirmModal';
import { SelectFilter } from '../../components/inputs/filters/select';
import { DateFilter } from '../../components/inputs/filters/date';
import { Strike } from '../../components/Strike';

export default function EmotionsPage() {
  const [update, setUpdate] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState<any>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pagination, setPagination] = useState<PaginationType>({} as PaginationType);
  const [queryParams, setQueryParams] = useState<any>({
    page: pagination?.current_page ?? 1,
    per_page: 10,
  });
  const { unit } = useUnit();
  const baseUrl = `/v1/admin/units/${unit}`;
  const {
    requestApi: reqApiDecisions,
    data: dataDecisions,
    loading: loadingDecisions,
  } = useFetch(`${baseUrl}/decisions`);
  const {
    requestApi: reqApiEmotions,
    data: dataEmotions,
    loading: loadingEmotions,
  } = useFetch(`${baseUrl}/emotions`);
  const { requestApi: reqApiUsers, data: dataUsers } = useFetch(`${baseUrl}/users`);

  const columns = [
    { name: 'decision', label: 'Decisão' },
    { name: 'emotion', label: 'Emoção' },
    { name: 'action_plan', label: 'Plano de ação' },
    { name: 'start_date', label: 'Início' },
    { name: 'execution_date', label: 'Fechamento' },
    { name: 'user', label: 'Responsável' },
    { name: 'status', label: 'Status' },
  ];

  const headerFilters = [
    {
      name: 'search',
      filterComponent: SearchFilter,
      placeholder: 'Buscar Decisão/Emoção',
    },
    {
      name: 'user_id',
      filterComponent: SelectFilter,
      placeholder: 'Filtrar Responsável',
      options: [
        ...(dataUsers?.data?.map(item => ({
          label: item?.name,
          value: item?.id,
        })) ?? []),
      ],
    },
    {
      name: 'start_date',
      filterComponent: DateFilter,
      placeholder: 'Data de Início',
    },
    {
      name: 'execution_date',
      filterComponent: DateFilter,
      placeholder: 'Data de Fechamento',
    },
    {
      name: 'status',
      filterComponent: SelectFilter,
      placeholder: 'Todos os Status',
      options: [
        {
          label: 'Em Aberto',
          value: 'Em aberto',
        },
        {
          label: 'Fechado',
          value: 'Fechado',
        },
      ],
    },
  ];

  useEffect(() => {
    if (!isOpen && unit) {
      reqApiDecisions('get', { params: queryParams });
      reqApiEmotions('get');
      reqApiUsers('get');
    }
    setDisabledSaveButton({});
  }, [queryParams, isOpen, update, unit]);

  useEffect(() => {
    setPagination(dataDecisions?.pagination as any);
  }, [dataDecisions]);

  const handleSubmit = async (event, decisionId, isUpdate = false, emotionId = null) => {
    event.preventDefault();
    const {
      target: { description, action_plan, start_date, execution_date, user, status },
    } = event;

    if (
      description.value &&
      action_plan.value &&
      start_date.value &&
      execution_date.value &&
      user.value &&
      status.value
    ) {
      await reqApiEmotions(
        isUpdate ? 'put' : 'post',
        {
          description: description.value,
          action_plan: action_plan.value,
          start_date: start_date.value,
          execution_date: execution_date.value,
          user_id: user.value,
          status: status.value,
          decision_id: decisionId,
        },
        isUpdate ? `${baseUrl}/emotions/${emotionId}` : null,
      );
      setUpdate(old => !old);
    }
  };

  const handleDeleteEmotion = async (emotionId: string) => {
    await reqApiEmotions('delete', null, `${baseUrl}/emotions/${emotionId}`);
    setUpdate(old => !old);
    // if (errorEmotions?.msg === undefined) {
    //   // await requestApi('get', null, `${baseUrl}${location.pathname}`);
    // }
  };
  function getEmotionStyle(item: any) {
    if (item.status === 'Fechado') return { textDecoration: 'line-through' };
    return {};
  }
  function getDecisionStyle(item: any) {
    console.log(item.description, dataEmotions?.data);
    const decisionEmotions =
      dataEmotions?.data.length > 0 &&
      dataEmotions?.data?.filter(emotion => emotion.decision_id === item.id);
    if (!decisionEmotions?.length || decisionEmotions?.length === 0) return {};
    const allClosed = decisionEmotions?.every(emotion => emotion.status === 'Fechado');
    return allClosed ? { textDecoration: 'line-through' } : {};
  }
  return (
    <Box width="100%" height="full" position="relative">
      <Flex align="center" mb={5}>
        {headerFilters?.map(filterProps => (
          <Box key={filterProps.name} mr={4}>
            <filterProps.filterComponent
              isDisabled={dataDecisions?.data?.length <= 0 && !queryParams[filterProps.name]}
              onChange={value => setQueryParams(old => ({ ...old, [filterProps.name]: value }))}
              options={filterProps.options}
              placeholder={filterProps.placeholder}
            />
          </Box>
        ))}
      </Flex>
      {!loadingEmotions && !loadingDecisions ? (
        <Box overflow="visible">
          {dataDecisions?.data?.length > 0 ? (
            <>
              <TableContainer
                border="1px solid"
                borderColor="gray.100"
                borderRadius="25px"
                boxShadow="0px 5px 10px #00000029"
              >
                <Table>
                  <Thead
                    borderRight="45px solid #EFEFEF"
                    borderLeft="45px solid #EFEFEF"
                    bg="#EFEFEF 0% 0% no-repeat padding-box"
                  >
                    <Tr>
                      {columns?.map(item => (
                        <Th pt="30px" key={item.name}>
                          {item.label}
                        </Th>
                      ))}
                      <Th pt="30px">Ações</Th>
                    </Tr>
                  </Thead>
                  <Tbody
                    _before={{
                      content: '""',
                      display: 'inline-block',
                      color: 'transparent',
                    }}
                    _after={{
                      content: '""',
                      display: 'inline-block',
                      color: 'transparent',
                    }}
                  >
                    {dataDecisions?.data?.map(decision => (
                      <>
                        <Tr key={decision.id}>
                          <form
                            onSubmit={e => handleSubmit(e, decision.id)}
                            id={`form${decision.id}0`}
                          />
                          <Td
                            style={getDecisionStyle(decision)}
                            py={0}
                            border="1px solid"
                            borderColor="gray.100"
                            rowSpan={
                              Number(
                                dataEmotions?.data.length > 0
                                  ? dataEmotions?.data?.filter(
                                      emotion => emotion.decision_id === decision.id,
                                    )?.length
                                  : 0,
                              ) + 2
                            }
                          >
                            <Box>
                              <Text>{decision.description}</Text>
                            </Box>
                          </Td>
                        </Tr>

                        {dataEmotions?.data.length > 0 &&
                          dataEmotions?.data
                            ?.filter(emotion => emotion.decision_id === decision.id)
                            ?.map(emotionItem => (
                              <Tr key={emotionItem.id}>
                                <form
                                  onSubmit={e => handleSubmit(e, decision.id, true, emotionItem.id)}
                                  id={`form${decision.id}${emotionItem.id}`}
                                  style={getEmotionStyle(emotionItem)}
                                />
                                <Td p={1} border="1px solid" borderColor="gray.100">
                                  <FormControl>
                                    <Editable
                                      defaultValue={emotionItem.description}
                                      h="100%"
                                      placeholder="Digite uma emoção"
                                    >
                                      <EditablePreview
                                        style={getEmotionStyle(emotionItem)}
                                        minWidth="100%"
                                      />
                                      <Input
                                        style={getEmotionStyle(emotionItem)}
                                        name="description"
                                        onChange={event => {
                                          if (event.target.value) {
                                            setDisabledSaveButton(old => ({
                                              ...old,
                                              [`form${decision.id}${emotionItem.id}`]: false,
                                            }));
                                          } else {
                                            setDisabledSaveButton(old => ({
                                              ...old,
                                              [`form${decision.id}${emotionItem.id}`]: true,
                                            }));
                                          }
                                        }}
                                        as={EditableInput}
                                        form={`form${decision.id}${emotionItem.id}`}
                                      />
                                    </Editable>
                                  </FormControl>
                                </Td>

                                <Td p={1} border="1px solid" borderColor="gray.100">
                                  <FormControl>
                                    <Editable
                                      defaultValue={emotionItem.action_plan}
                                      h="100%"
                                      placeholder="Digite um plano de ação"
                                    >
                                      <EditablePreview
                                        style={getEmotionStyle(emotionItem)}
                                        minWidth="100%"
                                      />
                                      <Input
                                        style={getEmotionStyle(emotionItem)}
                                        name="action_plan"
                                        onChange={event => {
                                          if (event.target.value) {
                                            setDisabledSaveButton(old => ({
                                              ...old,
                                              [`form${decision.id}${emotionItem.id}`]: false,
                                            }));
                                          } else {
                                            setDisabledSaveButton(old => ({
                                              ...old,
                                              [`form${decision.id}${emotionItem.id}`]: true,
                                            }));
                                          }
                                        }}
                                        as={EditableInput}
                                        form={`form${decision.id}${emotionItem.id}`}
                                      />
                                    </Editable>
                                  </FormControl>
                                </Td>

                                <Td
                                  p={1}
                                  border="1px solid"
                                  borderColor="gray.100"
                                  style={getEmotionStyle(emotionItem)}
                                >
                                  <FormControl style={getEmotionStyle(emotionItem)}>
                                    {emotionItem?.status === 'Fechado' && <Strike />}
                                    <Input
                                      style={getEmotionStyle(emotionItem)}
                                      name="start_date"
                                      type="date"
                                      onChange={event => {
                                        if (event.target.value) {
                                          setDisabledSaveButton(old => ({
                                            ...old,
                                            [`form${decision.id}${emotionItem.id}`]: false,
                                          }));
                                        } else {
                                          setDisabledSaveButton(old => ({
                                            ...old,
                                            [`form${decision.id}${emotionItem.id}`]: true,
                                          }));
                                        }
                                      }}
                                      defaultValue={moment(emotionItem.start_date).format(
                                        'yyyy-MM-DD',
                                      )}
                                      border="none"
                                      form={`form${decision.id}${emotionItem.id}`}
                                    />
                                  </FormControl>
                                </Td>

                                <Td p={1} border="1px solid" borderColor="gray.100">
                                  <FormControl>
                                    {emotionItem?.status === 'Fechado' && <Strike />}
                                    <Input
                                      style={getEmotionStyle(emotionItem)}
                                      name="execution_date"
                                      type="date"
                                      onChange={event => {
                                        if (event.target.value) {
                                          setDisabledSaveButton(old => ({
                                            ...old,
                                            [`form${decision.id}${emotionItem.id}`]: false,
                                          }));
                                        } else {
                                          setDisabledSaveButton(old => ({
                                            ...old,
                                            [`form${decision.id}${emotionItem.id}`]: true,
                                          }));
                                        }
                                      }}
                                      defaultValue={moment(emotionItem.execution_date).format(
                                        'yyyy-MM-DD',
                                      )}
                                      border="none"
                                      form={`form${decision.id}${emotionItem.id}`}
                                    />
                                  </FormControl>
                                </Td>

                                <Td p={1} border="1px solid" borderColor="gray.100">
                                  <FormControl>
                                    <Select
                                      style={getEmotionStyle(emotionItem)}
                                      name="user"
                                      defaultValue={emotionItem.user.id}
                                      variant="unstyled"
                                      placeholder="Responsável"
                                      onChange={event => {
                                        if (event.target.value) {
                                          setDisabledSaveButton(old => ({
                                            ...old,
                                            [`form${decision.id}${emotionItem.id}`]: false,
                                          }));
                                        } else {
                                          setDisabledSaveButton(old => ({
                                            ...old,
                                            [`form${decision.id}${emotionItem.id}`]: true,
                                          }));
                                        }
                                      }}
                                      form={`form${decision.id}${emotionItem.id}`}
                                    >
                                      {dataUsers?.data?.map(itemUser => (
                                        <option value={itemUser.id}>{itemUser.name}</option>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Td>

                                <Td p={1} border="1px solid" borderColor="gray.100">
                                  <FormControl>
                                    <Select
                                      style={getEmotionStyle(emotionItem)}
                                      name="status"
                                      defaultValue={emotionItem.status}
                                      variant="unstyled"
                                      placeholder="Status"
                                      onChange={event => {
                                        if (event.target.value) {
                                          setDisabledSaveButton(old => ({
                                            ...old,
                                            [`form${decision.id}${emotionItem.id}`]: false,
                                          }));
                                        } else {
                                          setDisabledSaveButton(old => ({
                                            ...old,
                                            [`form${decision.id}${emotionItem.id}`]: true,
                                          }));
                                        }
                                      }}
                                      form={`form${decision.id}${emotionItem.id}`}
                                    >
                                      <option value="Em Aberto">Em Aberto</option>
                                      <option value="Fechado">Fechado</option>
                                    </Select>
                                  </FormControl>
                                </Td>

                                <Td p={1} border="1px solid" borderColor="gray.100">
                                  <Flex>
                                    <SaveButton
                                      type="submit"
                                      disabled={
                                        disabledSaveButton[`form${decision.id}${emotionItem.id}`] ??
                                        true
                                      }
                                      form={`form${decision.id}${emotionItem.id}`}
                                    />
                                    <ConfirmModal
                                      handleDeleteItem={handleDeleteEmotion}
                                      itemId={emotionItem.id}
                                    />
                                  </Flex>
                                </Td>
                              </Tr>
                            ))}

                        <Tr>
                          <Td p={1} border="1px solid" borderColor="gray.100">
                            <FormControl isRequired>
                              <Editable h="100%" placeholder="Digite uma emoção">
                                <EditablePreview minWidth="100%" color="gray.400" />
                                <Input
                                  name="description"
                                  as={EditableInput}
                                  form={`form${decision.id}0`}
                                />
                              </Editable>
                            </FormControl>
                          </Td>

                          <Td p={1} border="1px solid" borderColor="gray.100">
                            <FormControl isRequired>
                              <Editable h="100%" placeholder="Digite um plano de ação">
                                <EditablePreview minWidth="100%" color="gray.400" />
                                <Input
                                  name="action_plan"
                                  as={EditableInput}
                                  form={`form${decision.id}0`}
                                />
                              </Editable>
                            </FormControl>
                          </Td>

                          <Td p={1} border="1px solid" borderColor="gray.100">
                            <FormControl isRequired>
                              <Input
                                name="start_date"
                                type="text"
                                placeholder="Data de início"
                                border="none"
                                onFocus={e => (e.target.type = 'date')}
                                onBlur={e =>
                                  e.target.value
                                    ? (e.target.type = 'date')
                                    : (e.target.type = 'text')
                                }
                                form={`form${decision.id}0`}
                              />
                            </FormControl>
                          </Td>

                          <Td p={1} border="1px solid" borderColor="gray.100">
                            <FormControl isRequired>
                              <Input
                                name="execution_date"
                                type="text"
                                placeholder="Data de início"
                                border="none"
                                onFocus={e => (e.target.type = 'date')}
                                onBlur={e =>
                                  e.target.value
                                    ? (e.target.type = 'date')
                                    : (e.target.type = 'text')
                                }
                                form={`form${decision.id}0`}
                              />
                            </FormControl>
                          </Td>

                          <Td p={1} border="1px solid" borderColor="gray.100">
                            <FormControl isRequired>
                              <Select
                                name="user"
                                variant="unstyled"
                                placeholder="Responsável"
                                form={`form${decision.id}0`}
                              >
                                {dataUsers?.data?.map(itemUser => (
                                  <option value={itemUser.id}>{itemUser.name}</option>
                                ))}
                              </Select>
                            </FormControl>
                          </Td>

                          <Td p={1} border="1px solid" borderColor="gray.100">
                            <FormControl isRequired>
                              <Select
                                name="status"
                                variant="unstyled"
                                placeholder="Status"
                                form={`form${decision.id}0`}
                              >
                                <option value="Em Aberto">Em Aberto</option>
                                <option value="Fechado">Fechado</option>
                              </Select>
                            </FormControl>
                          </Td>

                          <Td p={1} border="1px solid" borderColor="gray.100">
                            <Flex>
                              <SaveButton
                                // disabled={disabledSaveButton[`form${decision.id}0`] ?? true}
                                type="submit"
                                form={`form${decision.id}0`}
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      </>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              {dataDecisions?.data?.length > 10 && (
                <Paginator
                  pagination={pagination}
                  queryParams={queryParams}
                  setQueryParams={setQueryParams}
                />
              )}
            </>
          ) : (
            <Flex mt="100px" h="100%" align="center" justify="center">
              <EmptySvg />
              <Heading
                size="lg"
                maxW="300px"
                color="#4E5B88"
                ml="70px"
                textAlign="left"
                fontWeight="medium"
              >
                Não há dados a serem exibidos
              </Heading>
            </Flex>
          )}
        </Box>
      ) : (
        <Loader />
      )}

      <Draggable bounds="body">
        <Box position="fixed" w="250px" bottom="25px" right="80px" zIndex={10}>
          <ModalForm
            isOpen={isOpen}
            onClose={onClose}
            RenderedForm={DecisionForm}
            smallerModal
            onClick={onOpen}
            description="decisão"
          />
        </Box>
      </Draggable>
    </Box>
  );
}

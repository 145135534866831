import React, { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Box } from '@chakra-ui/react';
import { DataGrid } from '../../components/datagrid';
import { useFetch } from '../../hooks/useFetch';
import { SearchFilter } from '../../components/inputs/filters/search';
import { useUnit } from '../../hooks/useUnit';
import { DateFilter } from '../../components/inputs/filters/date';
import { SelectFilter } from '../../components/inputs/filters/select';

export default function MyDemandsPage() {
  // const { t } = useTranslation();
  const { unit } = useUnit();
  const baseUrl = `/v1/admin/units/${unit}/decisions?all=true`;
  const baseUrlSecondary = `/v1/admin/units/${unit}/users`;
  const { requestApi, data, loading, error } = useFetch(baseUrl);
  const { requestApi: reqApiUsers } = useFetch(baseUrlSecondary);

  const columns = [
    // { name: 'id', label: 'Id' },
    { name: 'description', label: 'Descrição' },
    { name: 'unit', label: 'Empresa' },
    { name: 'indicator', label: 'Indicador' },
    { name: 'user', label: 'Responsável' },
    { name: 'created_at', label: 'Data de Início' },
    { name: 'execution_date', label: 'Data de Fechamento' },
    { name: 'status', label: 'Status' },
  ];

  useEffect(() => {
    if (unit) {
      reqApiUsers('get');
    }
  }, [unit]);

  return (
    <Box h="full">
      <DataGrid
        limitFirstColumn
        doneRow="Fechado"
        columns={columns}
        rows={data?.data}
        requestApi={requestApi}
        error={error}
        baseUrl={baseUrl}
        data={data}
        getParam={unit}
        /*    createDescription="decisão"
        modalForm={DecisionForm} */
        loading={loading}
        rowActions={{ show: false, edit: true, delete: true }}
        formatColum={[
          {
            field: 'user',
            formatField: value => {
              return value.name;
            },
          },
          {
            field: 'unit',
            formatField: value => {
              return value?.client?.name;
            },
          },
          {
            field: 'created_at',
            formatField: value => {
              return moment(value).format('DD/MM/YYYY');
            },
          },
          {
            field: 'execution_date',
            formatField: value => {
              return value ? moment(value).format('DD/MM/YYYY') : 'Não informado';
            },
          },
        ]}
        headerFilters={[
          {
            name: 'search',
            filterComponent: SearchFilter,
            placeholder: 'Buscar Demanda',
          },
          {
            name: 'created_at',
            filterComponent: DateFilter,
            placeholder: 'Data de Início',
          },
          {
            name: 'execution_date',
            filterComponent: DateFilter,
            placeholder: 'Data de Fechamento',
          },
          {
            name: 'status',
            filterComponent: SelectFilter,
            placeholder: 'Todos os Status',
            options: [
              {
                label: 'Em Aberto',
                value: 'Em aberto',
              },
              {
                label: 'Fechado',
                value: 'Fechado',
              },
            ],
          },
        ]}
      />
    </Box>
  );
}

import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateUser = ({
  user,
  children,
  currentRoute,
}: {
  user: any;
  children: any;
  currentRoute: string;
}) => {
  const routeSettings = [
    {
      groupId: 1,
      name: 'system',
      routes: ['/clients', '/units', '/users', '/recover', '/profile', '/sectors', '/my-demands'],
      redirect: '/clients',
    },
    {
      groupId: 2,
      name: 'admin',
      routes: ['/clients', '/units', '/users', '/recover', '/profile', '/sectors', '/my-demands'],
      redirect: '/clients',
    },
    {
      groupId: 3,
      name: 'client',
      routes: [
        '/',
        '/numeros',
        '/analytics',
        '/decisions',
        '/emotions',
        '/recover',
        '/profile',
        '/insert-numbers',
        '/indicators',
        '/my-demands',
      ],
      redirect: '/',
    },
  ];

  const findUser = routeSettings.find(item => item.groupId === user.group.id);
  const permission = findUser?.routes.indexOf(currentRoute) !== -1;

  if (!permission) {
    return <Navigate to={`${findUser?.redirect}`} replace />;
  }

  return children;
};

export default PrivateUser;
